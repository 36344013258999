<template>
  <v-card min-height="400">
    <v-card-title>
      <v-col cols="12">
        Water usage
      </v-col>
      <v-col class="col-md-12">
        <table-filter
          :table-name="'water usage'"
          :default-date-picker-date="defaultDatePickerDate"
          @filter="applyFilters"
        />
      </v-col>
      <v-spacer></v-spacer>
    </v-card-title>
    <div>
      <v-tabs v-model="activeTab">
        <v-tab
          v-for="(meter, index) in stations"
          :key="index"
        >
          {{ meter.station }}
        </v-tab>
        <v-tab-item
          v-for="(meter, index) in stations"
          :key="index"
        >
          <div class="chart-container">
            <apexchart
              :options="chartOptions"
              :series="chartSeries[meter.id]"
              type="bar"
              height="400"
            />
          </div>
        </v-tab-item>
      </v-tabs>
    </div>
    <v-card-text v-show="!isLoading && chartSeries.length === 0">
      <p class="ml-4 font-weight-light">
        There is no enough data. Statistics will be displayed when enough data is gathered
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'
import VueApexCharts from 'vue-apexcharts'
import TableFilter from '@/components/partials/table-filter/index.vue'

export default {
  components: {
    apexchart: VueApexCharts,
    TableFilter,
  },
  props: {

  },
  data() {
    return {
      isLoading: false,
      activeTab: 0,
      stations: [],
      chartSeries: {},
      chartOptions: {
        toolbar: {
          show: false,
        },
        chart: {
          toolbar: {
            show: false,
          },
          height: 350,
          type: 'bar',
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          type: 'datetime',
        },
        yaxis: {
          title: {
            text: 'Units Consumed',
          },
        },
      },
      defaultDatePickerDate: 'Last 7 days',
      filters: {
        dates: {
          fromDate: '',
          toDate: '',
        },
      },
    }
  },
  watch: {
    activeTab(index) {
      // Render the chart for the selected tab
      this.setChartData(this.stations[index])
    },
  },
  mounted() {
    this.getReadings()
  },
  methods: {
    getReadings() {
      this.isLoading = true
      axios
        .get(`statistics/main-meter-readings?fromDate=${this.filters.dates.fromDate}&toDate=${this.filters.dates.toDate}`)
        .then(response => {
          this.stations = response.data
          this.setChartData(this.stations[this.activeTab], true)
        })
        .catch(error => {
          console.error('Error fetching main meter readings:', error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    setChartData(data, rerender = false) {
      const chartData = [{ name: 'Water extracted (Units)', data: data.main_meter_readings }, { name: 'Water consumed (Units)', data: data.meters_reading }]
      if (this.chartSeries[data.id]) {
        // Update existing chart data
        this.chartSeries[data.id].forEach((series, index) => {
          series.data = chartData[index].data
        })
      } else {
        // Add new chart data
        this.$set(this.chartSeries, data.id, chartData)
      }
      if (rerender) {
        this.$set(this.chartSeries, data.id, chartData)
      }
    },
    applyFilters(filters) {
      this.filters = filters
      this.getReadings()
    },
  },

}
</script>
