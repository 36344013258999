<template>
  <v-col>
    <v-row class="mt-1">
      <v-col>
        <vue-skeleton-loader
          class="mt-1"
          :width="150"
          :height="30"
        />
        <vue-skeleton-loader
          class="mt-2"
          :width="210"
          :height="15"
        />
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col>
        <vue-skeleton-loader
          type="circle"
          class="mt-1"
          :size="200"
        />
      </v-col>
      <v-col class="mt-50 ml-5">
        <vue-skeleton-loader
          class="mt-1"
          :width="80"
          :height="12"
        />
        <vue-skeleton-loader
          class="mt-1"
          :width="80"
          :height="12"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  components: {
    VueSkeletonLoader,
  },

}
</script>

<style>

</style>
